<script lang="ts" setup>
import type { Form, Url } from "@/types";

const { blok } = defineProps<{ blok: Form }>();
defineEmits(["close"]);

const formData = ref<{ [key: string]: any }>({});
const errors = ref<{ [key: string]: boolean }>({});
const hasErrors = ref(false);
const isSubmitting = ref(false);

const router = useRouter();

const { close } = useFormToggle();

const handleCompanyUpdated = (company: string) => {
  formData.value = {
    ...formData.value,
    company,
  };
};

const handleError = (status: boolean) => (hasErrors.value = status);

const redirectTo = (url?: Url) => {
  if (!url) return;

  if (url.linktype === "story") {
    return router.push(`/${url.cached_url}`);
  }

  if (url.linktype === "url") {
    return router.replace(url.cached_url);
  }
};

const handleFormSubmit = async () => {
  if (!blok.endpoint) {
    console.log(
      JSON.stringify({
        ...formData.value,
        timestamp: new Date(),
        url: location.href,
      }),
    );
    return;
  }

  const validation: { [key: string]: boolean } = {};

  for (let i = 0; i < blok.inputs.length; i++) {
    const input = blok.inputs[i];
    const isRequired = input.required;

    if (isRequired) {
      validation[input.name] = !formData.value[input.name];
    }

    errors.value = validation;
  }

  if (Object.values(errors.value).includes(true)) {
    return;
  }

  await useFetch(blok.endpoint, {
    method: "POST",
    body: { ...formData.value, timestamp: new Date(), url: location.href },
    mode: "no-cors",
    onRequest: () => {
      isSubmitting.value = true;
    },
    onRequestError: () => {
      isSubmitting.value = false;
    },
    onResponse: () => {
      formData.value = {};

      if (blok.successUrl) {
        close();
        redirectTo(blok.successUrl);
      }

      isSubmitting.value = false;
    },
    onResponseError: () => {
      if (blok.failureUrl) {
        close();
        redirectTo(blok.successUrl);
      }
      isSubmitting.value = false;
    },
  });
};
</script>

<template>
  <div v-editable="blok" class="md:max-w-[1240px] max-w-[375px]">
    <div class="flex flex-col lg:flex-row">
      <div
        class="p-5 pt-2.5 md:p-10 xl:p-20 flex flex-col gap-2.5 text-white items-start w-full lg:w-[450px]"
        :style="{
          backgroundColor: blok?.backgroundColor ?? 'inherit',
          color: blok?.fontColor ?? 'auto',
        }"
      >
        <div class="flex flex-col mb-5 lg:mb-0 items-start self-stretch">
          <IconCloseCircled
            class="w-8 h-8 md:w-11 md:h-11 self-end cursor-pointer lg:hidden"
            :color="blok?.fontColor || '#fff'"
            @click="$emit('close')"
          />
          <Heading
            :heading="blok.heading"
            :subtitle="blok.subtitle"
            class="flex flex-col gap-2.5"
          />
        </div>
        <RichText
          v-editable="blok"
          :content="blok.description"
          class="font-calson"
        />
        <BlokLink
          v-for="button in blok.button"
          :key="button._uid"
          :blok="button.url"
          class="flex gap-6 py-5 lg:py-0 items-center"
        >
          <span>{{ button.text }}</span>
          <IconArrowRight class="h-6 w-6" :color="blok?.fontColor ?? '#fff'" />
        </BlokLink>
      </div>
      <form
        class="flex flex-col flex-1 px-2.5 py-5 md:px-10 gap-2.5 col-span-2 bg-white"
        @submit.prevent="handleFormSubmit"
      >
        <div class="self-end hidden lg:block">
          <div class="cursor-pointer" @click="$emit('close')">
            <IconCloseCircled />
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
          <component
            :is="input.component"
            v-for="input in blok.inputs || []"
            :key="input._uid"
            v-model="formData[input.name]"
            :blok="input"
            :company-name="formData.company"
            :has-error="errors[input.name]"
            @company-update="handleCompanyUpdated"
            @validate="handleError"
          />
        </div>
        <div class="self-end">
          <button
            type="submit"
            class="py-5 px-12 rounded-full text-white bg-black disabled:opacity-60"
            :disabled="hasErrors || isSubmitting"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
